import { Blackjack } from "./blackjack";

export function Game() {
  return (
    <div>
      <h1>Games</h1>
      <hr />
      <Blackjack />
    </div>
  );
}
